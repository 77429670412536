import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ChatContactInfoField } from 'models/contact-info-fields'
import { Paginator } from 'models/paginator'
import { EMPTY, Observable, of } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'
import * as deskAction from '../../actions/desk'
import { HubtypeCase } from '../../models/hubtype-case'
import { HubtypeChat } from '../../models/hubtype-chat'
import { HubtypeMessage } from '../../models/hubtype-message'
import { HubtypeUser } from '../../models/hubtype-user'
import * as fromRoot from '../../reducers'
import { ConverterService } from '../converter.service'
import { HubtypeApiService } from './hubtype-api.service'

export enum OlderMessagesDispatchTarget {
  ARCHIVE = 'archive',
  CASE = 'case',
}

export enum OlderMessagesSource {
  CHAT = 'CHAT',
  CASE = 'CASE',
}
const PAGE_SIZE_MESSAGES = 20
const PAGE_SIZE_OLD_MESSAGES = 15
@Injectable()
export class ChatService {
  constructor(
    private store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  downloadChatsProviderAccountId(
    providerAccountId: string,
    formula?: string
  ): Observable<string> {
    return this.apiService.get(
      '/chats/export/',
      {
        formula,
        provider_account_id: providerAccountId,
      },
      'v1',
      true,
      'csv'
    )
  }

  // GET /chats/{pk}/messages/?project_id message_id count
  getOlderMessages(
    chat: HubtypeChat | undefined,
    case_: string | undefined,
    last_msg_id: string | undefined,
    count: number | undefined
  ): Observable<HubtypeMessage[]> {
    if (!chat || !chat.id) {
      // when case has no chat, we get a chat with empty fields
      return EMPTY
    }
    const url = `/chats/${chat.id}/messages/`
    const params = {}
    if (case_) {
      params['case_id'] = case_
    }
    if (last_msg_id) {
      params['message_id'] = last_msg_id
    }
    if (count) {
      params['count'] = count
    }
    return this.apiService
      .get(url, params)
      .pipe(
        map<any, HubtypeMessage[]>(response =>
          this.convertService.jsonConvert.deserializeArray(
            response.messages,
            HubtypeMessage
          )
        )
      )
    // map(response => plainToClass(HubtypeMessage, response.messages));
  }

  getOlderMessagesDispatch(
    chat: HubtypeChat,
    case_: HubtypeCase,
    lastMessageId: string,
    count: number = PAGE_SIZE_OLD_MESSAGES,
    source: OlderMessagesSource = OlderMessagesSource.CHAT,
    target: OlderMessagesDispatchTarget = OlderMessagesDispatchTarget.CASE
  ): Observable<HubtypeMessage[]> {
    const caseId = source === OlderMessagesSource.CASE ? case_.id : undefined
    return this.getOlderMessages(chat, caseId, lastMessageId, count).pipe(
      first(),
      map(messages => {
        if (messages) {
          this.updateStoreMessages(case_, messages, target)
        }
        return messages
      })
    )
  }

  // POST: /chats/{chatId}/ban/
  ban(chatId): Observable<HubtypeUser> {
    const url = `/chats/${chatId}/ban/`
    return this.apiService.post(url)
  }

  banDispatch(chatId): Observable<HubtypeUser> {
    return this.ban(chatId).pipe(
      first(),
      map(u => {
        this.store.dispatch(new deskAction.BanUserAction(u as HubtypeUser))
        return u
      })
    )
  }

  // POST: /chats/{chatId}/unban/
  unban(chatId): Observable<HubtypeUser> {
    const url = `/chats/${chatId}/unban/`
    return this.apiService.post(url)
  }

  mediaAndNoteMessages(chatId): Observable<HubtypeMessage[]> {
    const url = `/chats/${chatId}/media_and_note_messages/`
    return this.apiService
      .get(url)
      .pipe(
        map<any, HubtypeMessage[]>(response =>
          this.convertService.jsonConvert.deserializeArray(
            response.messages,
            HubtypeMessage
          )
        )
      )
  }

  getChatContactInfoFields(chatId: string): Observable<{
    fields: ChatContactInfoField[]
  }> {
    return this.apiService.get(`/chats/${chatId}/contact_info_fields/`).pipe(
      first(),
      map(field => ({
        fields: this.convertService.jsonConvert.deserializeArray(
          field,
          ChatContactInfoField
        ),
      }))
    )
  }

  createChatContactInfoField(
    chatId: string,
    fieldId: string,
    fieldValue: string
  ): Observable<any> {
    return this.apiService
      .post(`/chats/${chatId}/contact_info_fields/`, {
        value: fieldValue,
        contact_info_field_id: fieldId,
      })
      .pipe(first())
  }

  updateChatContactInfoField(
    chatId: string,
    fieldId: string,
    fieldValue: string
  ): Observable<ChatContactInfoField> {
    return this.apiService
      .patch(`/chats/${chatId}/contact_info_fields/`, {
        value: fieldValue,
        contact_info_field_id: fieldId,
      })
      .pipe(
        first(),
        map(json =>
          this.convertService.jsonConvert.deserializeObject(
            json,
            ChatContactInfoField
          )
        )
      )
  }

  deleteChatContactInfoField(chatId: string, fieldId: string): Observable<any> {
    return this.apiService
      .delete(`/chats/${chatId}/contact_info_fields/`, null, {
        contact_info_field_id: fieldId,
      })
      .pipe(first())
  }

  getMessages(
    chatId: string,
    from: Date,
    to: Date
  ): Observable<HubtypeMessage[]> {
    const url = '/messages/'
    const params = {
      chat_id: chatId,
      from_datetime: from.toISOString(),
      to_datetime: to.toISOString(),
      order: 'created_at',
    }

    return this.apiService
      .get(url, params, 'v2')
      .pipe(
        map((response: Paginator<HubtypeMessage[]>) =>
          this.convertService.jsonConvert.deserializeArray(
            response.results,
            HubtypeMessage
          )
        )
      )
  }

  loadMessagesInStore(
    hcase: HubtypeCase,
    from: string,
    to: string,
    target: OlderMessagesDispatchTarget
  ) {
    const url = '/messages/'
    const params = {
      chat_id: hcase.chat.id,
      from_datetime: from,
      to_datetime: to,
      order: 'created_at',
      page_size: PAGE_SIZE_MESSAGES,
    }
    return this.apiService.get(url, params, 'v2').pipe(
      first(),
      switchMap((response: Paginator<HubtypeMessage[]>) => {
        const messages = this.convertService.jsonConvert.deserializeArray(
          response.results,
          HubtypeMessage
        )
        this.updateStoreMessages(hcase, messages, target)
        const cursor = this.getCursorParameter(response.next)
        if (cursor) {
          return this.loadCursorMessagesInStore(hcase, from, to, target, cursor)
        }
        return of(true)
      })
    )
  }

  private loadCursorMessagesInStore(
    hcase: HubtypeCase,
    from: string,
    to: string,
    target: OlderMessagesDispatchTarget,
    cursor: string
  ) {
    const url = '/messages/'
    const params = {
      chat_id: hcase.chat.id,
      from_datetime: from,
      to_datetime: to,
      order: 'created_at',
      cursor,
      page_size: PAGE_SIZE_MESSAGES,
    }

    return this.apiService.get(url, params, 'v2').pipe(
      switchMap(response => {
        const messages = this.convertService.jsonConvert.deserializeArray(
          response.results,
          HubtypeMessage
        )
        this.updateStoreMessages(hcase, messages, target)

        if (response.next) {
          const newCursor = this.getCursorParameter(response.next)
          return this.loadCursorMessagesInStore(
            hcase,
            from,
            to,
            target,
            newCursor
          )
        }
        return of(true)
      })
    )
  }

  private updateStoreMessages(
    hcase: HubtypeCase,
    messages: HubtypeMessage[],
    target
  ) {
    const payload = { case: hcase, messages }
    if (target === OlderMessagesDispatchTarget.CASE) {
      this.store.dispatch(new deskAction.LoadOldChatMessagesAction(payload))
    } else if (target === OlderMessagesDispatchTarget.ARCHIVE) {
      this.store.dispatch(new deskAction.UpdateArchiveMessagesAction(payload))
    }
  }

  private getCursorParameter(url: string = '') {
    if (!url) {
      return ''
    }
    const parameters = new URLSearchParams(url.split('?')[1])
    return parameters.has('cursor') ? parameters.get('cursor') : ''
  }
}
